import React from 'react';
import {withAllSubjects} from './hooks/withAllSubjects'
import {SubjectCard} from './cards'
import Styles from './archive.module.css'
import {navigate} from 'gatsby'

export const SubjectsArchive = (props) => {

    const subjects = withAllSubjects(props.topic)
  return (
    <>
      <div className={Styles.grid} >
    {subjects && subjects.length>0 && subjects.map(subject => (
      <div className={Styles.item} key={subject.id} onClick={()=>{navigate(subject.path)}}>
        <SubjectCard {...subject}/>
      </div>
    ))}
    </div>
    </>
  )
}
