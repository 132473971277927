import React from 'react'
import {ContentWithShare} from 'components/ContentWithShare'
import TwitterTimeLine from 'components/twitterTimeLine'
import {Header} from './parts/header'
import {SubjectsArchive} from 'components/subjects/archive'
import {BgPattern} from 'components/backgrounds'
import BgStyles from 'components/backgrounds/patterns.module.css'
import Styles from './topic.module.css'
export const Content = (props) => (
  <>
    <header className="entry-header mb-10">
      <Header {...props}/>
    </header>
  <ContentWithShare
    twitter={true}
    facebook={true}
    linkedin={true}>
    <div className="entry-content" dangerouslySetInnerHTML={{__html:props.content}}/>
  </ContentWithShare>
    <footer className="entry-footer mt-24">
      <div className="mb-24">
        <div className="container mx-auto">
          <div className="text-center mb-10">
            <h1 className="mt-4 font-semibold text-4xl">Related Subjects</h1>
          </div>
          <div className="relative">
            <BgPattern className={`${BgStyles.pattern1} ${Styles.subjectsBackground}` }/>
          <SubjectsArchive
            topic={{...props}}
          />

          </div>
        </div>
      </div>
      <div className="mb-16">
        <div className="text-center mb-10">
          <h1 className="mt-4 font-semibold text-4xl">Join the conversation</h1>
        </div>
        <TwitterTimeLine
          numberOfTweets={5}
        />
      </div>
    </footer>
  </>
)
