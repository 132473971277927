import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'


export const withAllSubjects = (topic) => {
  const {subjects} = useStaticQuery(graphql `
    query AllSubjects{
      subjects:allWordpressWpSubjects{
        nodes {
          id
          title
          path
          type
          excerpt
          wordpress_id
          topics{
            id
            title
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 368, maxHeight: 351){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
}
`)

  if(subjects.nodes){
  return subjects.nodes.filter(e=>{
      const topic_ids = [].concat(e.topics.map(t=>t.id))
      return topic_ids.includes(topic.id)
    })
    
  }else {
    return []
  }
}
