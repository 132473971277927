import React from "react"
import {graphql} from 'gatsby'
import Layout from "components/layout"
import {Content} from 'components/topics/content'

const SingleTopicsTemplate = (props) => {
  const data = props.data;
  const {content, date, title, featured_media, tags, slug, classes, ancestors, related_news_section} = data.wordpressWpTopics
  return(
  <Layout
    articleId={"topics-"+slug}
    context = {props.pageContext}
    location = {props.location}
    ancestors = {ancestors}
  >
    <div className="entry">
      <Content {...data.wordpressWpTopics}/>
    </div>
    
  </Layout>
  )
}

export default SingleTopicsTemplate

export const topicQuery = graphql `
query topicById($id: String!){
  wordpressWpTopics(id:{eq:$id}){
    id
    title
    content
    path
    slug
    type
    classes
    date(formatString: "LL")
    wordpress_id
    excerpt
    ancestors {
      link
      label
    }
    featured_media {
      localFile {
        ...PageBannerImages
      }
    }
  }
}

`
