import React from 'react'
import Styles from './subjectcard.module.css'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import {ReadMoreButton} from 'components/buttons'
import {navigate} from 'gatsby'
import {sliceExcerpt} from 'helpers'

export const SubjectCard = (props) => {
  const [hover, setHover] = React.useState(false)
  const has_image = props.featured_media && props.featured_media.localFile && props.featured_media.localFile.childImageSharp && props.featured_media.localFile.childImageSharp.fluid
  return (
    <div
      className={Styles.card}
     
      onMouseEnter={()=>setHover(true)}
      onMouseLeave={()=>setHover(false)}

    >
      { has_image && !hover && 
      <BackgroundImage
        tag="div"
        className={Styles.background}
        fluid={props.featured_media.localFile.childImageSharp.fluid}
      >
        <div className="flex flex-col h-full w-full justify-end p-5">
        <p dangerouslySetInnerHTML={{__html: props.title}} className="font-bold text-4xl text-white" style={{lineHeight:1}}/>
        </div>
        
      </BackgroundImage>
      }
      {  !has_image || hover && 
      <div className={Styles.content}>
        <p className={Styles.title} dangerouslySetInnerHTML={{__html:props.title}}/>
        <div className={Styles.excerpt} dangerouslySetInnerHTML={{__html:sliceExcerpt(props.excerpt,120)}}/>
        <div>
          <ReadMoreButton
            textClass='text-sm'
            className="mt-5"
            symbolColor="#AAC939"
            link={{
              url: props.path,
              title: props.title
            }}
        />
        </div>

      </div>
      }
    </div>
  )
}
